import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

const Team = () => {
    return (
        <div>
            <div className=" bg-gold">
                <Container className="bg-gold">
                    <Card className="border-0 pt-5 pb-5 mb-5 bg-gold">
                        <Card.Body className="pb-5">
                            <Card.Title className="fw-bold display-1 text-black" style={{ textRendering: 'optimizeLegibility' }}>
                                Meet the Team
                            </Card.Title>
                            <Card.Text className="pb-4 text-black" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                Program designed by Award Winning Research Team
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
            <Container>
                <h1 className="display-2 fw-bold text-center">Core Team</h1>
                <Row className="row-5 pb-5 mb-5 text-center">
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className="d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Siddharth.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-4 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Siddharth Krishnan
                                </Card.Title>
                                <p className="fw-bold" style={{ textRendering: 'optimizeLegibility', fontSize: '25px' }}>Co-Founder & AI Program Director</p>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Faculty member of Computer Science at UNC Charlotte with 10+ years in AI & ML research, 25+ publications in leading CS conferences and journals including three best paper awards;
                                    has also led several AI project development and implementation efforts at UNC Charlotte and Virgnia Tech. At UNC Charlotte’s College of Computing and Informatics,
                                    Dr. Krishnan co-directs the Scalable Machine Research Laboratory and teaches AI & ML courses both at the undergraduate and graduate levels.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className=" d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Joel.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Joel Reji
                                </Card.Title>
                                <p className="fw-bold" style={{ textRendering: 'optimizeLegibility', fontSize: '25px' }}>Co-Founder & Operations Director</p>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Joel is a Vice President of Treasury Data at Bank of America. He is also a founder of an AI/ML startup based in Charlotte NC.
                                    He earned his Masters in Data Science & Business Analytics from UNC Charlotte and a Bachelor's in Economics & Computer Science
                                    from NC State University. His startup focuses on democratizing access to machine learning building easy to use machine learning
                                    components.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className=" d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Taylor.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Taylor Faulkner
                                </Card.Title>
                                <p className="fw-bold" style={{ textRendering: 'optimizeLegibility', fontSize: '25px' }}>Outreach & Partnerships Director</p>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Taylor has worked with UNC Charlotte's Camps on Campus program for eight years now, advancing from counselor, to lead counselor, to Associate Director, Interim Director for Summer 2022, and now Camp Director.
                                    Taylor is also the Youth Programs Manager in the School of Professional Studies and manages a portfolio of youth-centered programs. With a background in education and teaching, she has dedicated her life to providing opportunities for the region's children through collaboration and partnerships with educators.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <h1 className="display-2 fw-bold text-center">Program Partners</h1>
                <Row className="row-5 text-center">
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className=" d-flex flex-column text-center">
                                <Card.Img src="/Monika.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Monika Akbar
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Monika is an Associate Professor of the Department of Computer Science at The University of Texas at El Paso (UTEP).
                                    Her academic background includes a Ph.D. in Computer Science from Virginia Tech and an M.S. in Computer Science from Montana State University-Bozeman.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className=" d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Ram.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Ram Bala
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Ram is an Associate Professor of Business Analytics at the Leavey School of Business, Santa Clara University. He holds a Ph.D. in Operations Research from the UCLA.
                                    Ram is recognized as a world-renowned researcher in using machine learning for business use cases.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className=" d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Razvan.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Razvan Bunescu
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Razvan is an Associate Professor of Computer Science at UNC Charlotte.
                                    He received his Ph.D. at the University of Austin. His research focuses on AI, natural language processing, & recommender systems.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="row-5 text-center">
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className="d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Srijan.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Srijan Das
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Srijan is an Assistant Professor at University of North Carolina Charlotte. He was a Postdoc at Stony Brook University, New York.
                                    He completed his Ph.D. in computer science from University Côte d'Azur and Inria Sophia Antipolis.
                                    His research focuses on AI Computer Vision and self driving cars.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className="d-flex flex-column text-center">
                                <Card.Img src="/Samah.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Samah Gad
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Samah is an experienced engineering and machine learning leader with over 17 years of industry experience spanning research and teaching,
                                    with over 9 years managing large engineering organizations. She earned her PhD in Computer Science from Virginia Tech.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className=" d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Shahriar.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-4 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. M Shahriar Hossain
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Shahriar is an Associate Professor in the Department of Computer Science at the University of Texas at El Paso (UTEP).
                                    He received a Ph.D. in Computer Science from Virginia Tech and joined the Department of Computer Science at UTEP.
                                    His research focuses on Data Science, with a specialization in Big Data Mining and Machine Learning.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="row-5 pb-5 mb-5 text-center">
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className="d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Maya.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Maya Kapoor
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Software Engineer and Research Scientist at Parsons Corporation.
                                    Her research interests are in security, encryption, data processing and knowledge discovery, and networking.
                                    Received her Ph.D. in Computing from UNC Charlotte in 2023. Has published extensively in peer-reviewed conferences and journals in the last five years.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className="d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Christian.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-4 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Christian Kümmerle
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Christian is an Assistant Professor of Computer Science at UNC Charlotte.
                                    He received his Ph.D. from the Technical University of Munich and PostDoc from Johns Hopkins University.
                                    His research addresses computational and statistical challenges arising from AI & ML systems.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="col-12 col-md">
                        <Card className="border-0 pt-5 row-5 h-100">
                            <Card.Body className="d-flex flex-column text-center"> {/* Add text-center class here */}
                                <Card.Img src="/Thomas.jpeg" alt="Image Description" className="card-img-top profile-picture" />
                                <Card.Title className="fw-bold py-5 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                    Dr. Thomas Moyer
                                </Card.Title>
                                <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                    Thomas is a staff software engineer at Canonical & an adjunct professor at UNC Charlotte.
                                    He was a research scientist at MIT Lincoln Laboratory in the Secure Resilient Systems and Technology Group.
                                    He received his Ph.D. at Penn State and his research focuses at the intersection of AI & Cybersecurity.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Team;
