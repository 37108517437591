import React, { useEffect, useState, useRef } from "react";
import { Button, Container, Row, Col, Card, Modal } from "react-bootstrap"
import './Components.css';


const Home = () => {

    const applyCardRef = useRef(null);

    const scrollToApplyCard = () => {
        if (applyCardRef.current) {
            applyCardRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div id="content">
            <div className="py-5 bg-gold">
                <Container className="py-5 mb-5 bg-gold">
                    <Card className="border-0 pt-5 pb-5 mb-5 bg-gold">
                        <Card.Body className="pb-5">
                            <Card.Title className="fw-bold pb-4 display-3 text-black" style={{ textRendering: 'optimizeLegibility' }}>
                                ZenStar AI Research Academy
                            </Card.Title>
                            <Card.Text className="pb-4 text-black" style={{ textRendering: 'optimizeLegibility', fontSize: '25px' }}>
                                ZenStar AI is a program that exposes students, 8<sup>th</sup> to 12<sup>th</sup> grade, to fundamental artificial intelligence (AI) and machine learning (ML) concepts,
                                guides them to build socially impactful projects, and conduct cutting-edge research.
                                Taught by a team of both academic & industry experts in AI & ML.
                            </Card.Text>
                            <Button onClick={scrollToApplyCard} className="btn-dark btn-lg custom-button mt-3 mb-5 bg-black">Apply Now</Button>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
            <div className=" pb-5">
                <Container className="row-4">
                    <Row className="row-4 py-5 mb-5">
                        <Col className="col-12 col-md">
                            <Card className="border-0 row-4 p-3">
                                <Card.Body>
                                    <Card.Title className="fw-bold display-6 pb-4">Mission</Card.Title>
                                    <Card.Text className="pb-4" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                        ZenStar AI Research Academy <span className="fw-bold">empowers</span> curious students by teaching them how artifical intelligence is being used all around them.
                                        Inspiring our students to dig deeper into how Netflix recommends a new show or why chatGPT is able to understand their questions.
                                        This program was specially curated by both academic & corporate professionals from top universitites/organizations to help guide
                                        students down a path of academic & industry success with artifical intelligence
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="col-12 col-md">
                            <Card className="border-0 row-2 p-3">
                                <div className="overlay-container pt-5">
                                    <img
                                        src="/success.jpg"
                                        alt="Your Image"
                                        className="img-fluid rounded"
                                        style={{ maxWidth: "100%", height: "auto" }}
                                    />
                                    <div className="overlay"></div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="border-0 text-center">
                        <Card.Body className="">
                            <Card.Title className="fw-bold pb-4 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                Build AI Projects For Social Impact
                            </Card.Title>
                            <Card.Text className="pb-4" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                Our team of instructors with deep industry and academic experience will mentor projects in a variety of domains.
                                Come explore the intersection of AI and your favorite topic: healthcare, finance, law, education, astronomy AND MORE!
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Row className="row-5 pb-5 mb-5">
                        <Col className="col-12 col-md">
                            <Card className="border-0 pt-5 row-5 h-100">
                                <Card.Body className="bg-black text-white d-flex flex-column">
                                    <Card.Img src="/compVis.jpg" alt="Image Description" className="card-img-top" style={{ objectFit: 'cover', height: '400px' }} />
                                    <Card.Title className="fw-bold py-4 display-6 custom-color" style={{ textRendering: 'optimizeLegibility' }}>
                                        AI + Autonomous Driving
                                    </Card.Title>
                                    <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '15px' }}>
                                        In this project, students learn more about how self-driving cars are able to detect and classify objects in the road.
                                        Students will build neural networks to classify various objects in the road like other cars, street signs, people etc.
                                        This project will give students an opportunity to learn how to implement powerful neural networks for computer vision problems.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="col-12 col-md">
                            <Card className="border-0 pt-5 row-5 h-100">
                                <Card.Body className="bg-black text-white d-flex flex-column">
                                    <Card.Img src="/chat.jpg" alt="Image Description" className="card-img-top" style={{ objectFit: 'cover', height: '400px' }} />
                                    <Card.Title className="fw-bold py-5 display-6 custom-color" style={{ textRendering: 'optimizeLegibility' }}>
                                        AI + Chat
                                    </Card.Title>
                                    <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '15px' }}>
                                        In this project, students will study the technology behind ChatGPT, Bard, and other cutting-edge AI models.
                                        Use ChatGPT to build customized chat applications like a sports coach or a habbit tracker or an automatic stock investor.
                                        Use OpenAI API to access and leverage LLMs into your own applications.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="col-12 col-md">
                            <Card className="border-0 pt-5 row-5 h-100">
                                <Card.Body className="bg-black text-white d-flex flex-column">
                                    <Card.Img src="/health.jpg" alt="Image Description" className="card-img-top" style={{ objectFit: 'cover', height: '400px' }} />
                                    <Card.Title className="fw-bold py-5 display-6 custom-color" style={{ textRendering: 'optimizeLegibility' }}>
                                        AI + Healthcare
                                    </Card.Title>
                                    <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '15px' }}>
                                        In this project, students learn more about how to use AI to detect various illnesses like Skin Cancer or Penumonia.
                                        Students will have an  opportunity to learn how to study the spread of COVID-19 using powerful data wrangling and
                                        visualization methodologies. Students will also have the opportunity to build machine learning models to help with diagnoses,
                                        while learning how to evaluate model error.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card className="border-0 pt-5 text-center">
                        <Card.Body className="pb-5">
                            <Card.Title className="fw-bold pb-4 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                Program designed by Dr. Siddharth Krishnan
                            </Card.Title>
                            <Card.Text className="pb-4" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                Faculty member of Computer Science at UNC Charlotte with 10+ years in AI & ML research, 25+ publications in leading CS conferences and journals including three best paper awards;
                                has also led several AI project development and implementation efforts at UNC Charlotte and Virgnia Tech.
                                He received his Ph.D. in Computer Science from Virginia Tech.
                                He co-directs the Scalable Machine Research Laboratory and teaches AI & ML courses both at the undergraduate and graduate levels.
                            </Card.Text>
                            <Button href="/team" className="btn btn-lg btn-dark bg-black">Meet the Team</Button>
                        </Card.Body>
                    </Card>
                    <Row className="row-4 pt-5 mb-5">
                        <Col className="col-12 col-md">
                            <Card className="border-0 row-4 p-3">
                                <Card.Body>
                                    <Card.Title className="fw-bold display-6 pb-4">Learning outcomes paired with student success</Card.Title>
                                    <Card.Text className="pb-4" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                        <ul>
                                            <li className="pt-5 pb-3">Understand foundational Artifical Intelligence & Machine Learning Concepts</li>
                                            <li className="pb-3">Students will implement cutting edge AI/ML models using various Python libraries.</li>
                                            <li className="pb-3">Learn the full AI engineering lifecycle from pre-processing datasets to deploying models.</li>
                                            <li className="pb-3">Takeaway completed project code with industry standard version control</li>
                                            <li className="pb-3">Recieve a certificate of completion</li>
                                            <li className=""> Top performing students may continue to receive mentorship and work towards getting published in a research journal, presenting at a conference, receive recommendation letters, which will potentially lead to gaining acceptance into their dream schools.</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="col-12 col-md">
                            <Card className="border-0 row-2 p-5">
                                <div className="overlay-container py-5">
                                    <img
                                        src="/student_success.jpg"
                                        alt="Your Image"
                                        className="img-fluid rounded py-5"
                                        style={{ maxWidth: "100%", height: "auto" }}
                                    />
                                    <div className="overlay"></div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container className="mb-3">
                    <Row className="row-5 pt-2 pb-5">
                        <Col className="col-12 col-md">
                            <Card className="border-0  row-5 row-2 p-3">
                                <div className="overlay-container pt-5">
                                    <img src="/hs.jpg" alt="Your Image" className="img-fluid rounded" style={{ maxWidth: "100%", height: "auto" }} />
                                    <div className="overlay"></div>
                                </div>
                            </Card>
                        </Col>
                        <Col className="col-12 col-md">
                            <Card className="border-0 pt-5 row-5">
                                <Card.Body className="pb-5">
                                    <Card.Title className="fw-bold pb-4 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                        Ideal candidates
                                    </Card.Title>
                                    <Card.Text className="pb-4" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                        <ul>
                                            <li className="pb-3">Students from Grade 8 through 12</li>
                                            <li className="pb-3">Interested in how complex AI sytems work. Have you wondered about how Netflix knows what shows you might like?</li>
                                            <li className="pb-3">No computers or programming background required. We will teach you enough programming to work with AI</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Container className="mb-3">
                    <Card className="border-0 text-center">
                        <Card.Body className="">
                            <Card.Title className="fw-bold pb-4 display-6" style={{ textRendering: 'optimizeLegibility' }}>
                                Core AI Technologies In Program
                            </Card.Title>
                            <Card.Text className="pb-4" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                We are focused on the latest advancements in AI technology.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Row className="row-5 pb-5 mb-5">
                        <Col className="col-12 col-md">
                            <Card className="border-0 pt-5 row-5 h-100">
                                <Card.Body className="bg-black text-white d-flex flex-column">
                                    <Card.Title className="fw-bold py-4 display-6 custom-color text-center" style={{ textRendering: 'optimizeLegibility' }}>
                                        Natural Language Processing
                                    </Card.Title>
                                    <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '15px' }}>
                                        How does Siri or Alexa understand the questions we ask them? Learn about the techniques used
                                        by leading scientists to help computers understand our natural language.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="col-12 col-md">
                            <Card className="border-0 pt-5 row-5 h-100">
                                <Card.Body className="bg-black text-white d-flex flex-column">
                                    <Card.Title className="fw-bold py-5 display-6 custom-color text-center" style={{ textRendering: 'optimizeLegibility' }}>
                                        Computer Vision
                                    </Card.Title>
                                    <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '15px' }}>
                                        How does facial recognition work? Dive into how computers understand the visual world and get an understanding how self-driving cars work.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="col-12 col-md">
                            <Card className="border-0 pt-5 row-5 h-100">
                                <Card.Body className="bg-black text-white d-flex flex-column">
                                    <Card.Title className="fw-bold py-4 display-6 custom-color text-center" style={{ textRendering: 'optimizeLegibility' }}>
                                        Recommendation System
                                    </Card.Title>
                                    <Card.Text style={{ textRendering: 'optimizeLegibility', fontSize: '15px' }}>
                                        How does Netlfix suggest a movie or show you might like? Dive into how computers can understand the similarity between human elements.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Card ref={applyCardRef} id="apply" className="border-0 pt-5 row-5">
                        <Card.Body className="">
                            <Card.Title className="fw-bold pb-4 display-6 text-center" style={{ textRendering: 'optimizeLegibility' }}>
                                Join us today!
                            </Card.Title>
                            <Card.Text className="pb-4 text-center" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                Our Live Online program is delivered online through live video classes in small groups with our team of experienced AI mentors.
                                Our sessions are held once a week during the weekend to cater to the most students!
                                <br>
                                </br>
                                <br>
                                </br>
                                <strong style={{ fontSize: '25px' }}>Program Pricing</strong>
                                <ul className="py-2" style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
                                    <li className="fw-bold">Program Fee: $999</li>
                                </ul>
                                <br></br>
                                <br></br>
                                <p className="fw-bold pt-5"> Click below to apply to our cohort! You will hear back from us within 48 hours of applying.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Row className="row-5 py-5">
                        <Col className="col-12 col-md pb-5">
                                <Card className="pt-5 row-5 card-link muted-card">
                                    <Card.Body className="">
                                        <Card.Title className="fw-bold pb-4 display-6 text-center" style={{ textRendering: 'optimizeLegibility' }}>
                                            Spring
                                        </Card.Title>
                                        <Card.Text className="pb-4 text-center" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                            12 classes from January to April
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                        </Col>
                        <Col className="col-12 col-md pb-5">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScBi7yWSMshNQ0suYpUGIB_YD4bkUttdmRJ-yttALL1I97IHg/viewform?usp=sf_link" className="card-link">
                            <Card className="pt-5 row-5 card-link">
                                <Card.Body className="">
                                    <Card.Title className="fw-bold pb-4 display-6 text-center" style={{ textRendering: 'optimizeLegibility' }}>
                                        Fall
                                    </Card.Title>
                                    <Card.Text className="pb-4 text-center" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }}>
                                        12 classes from September to November
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Home;