import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";



const Header = () => {

    return (
        <Navbar bg="black" variant="dark" expand="lg" sticky="top" className="navbar-opacity">
            <Container>
                <Navbar.Brand className="text-white" style={{ textRendering: 'optimizeLegibility', fontSize: '20px' }} href="/">ZenStar AI Research Academy</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end text-white">
                    <Nav className="justify-content-end text-white">

                        <>
                            <Nav.Link className="text-white" href="/team">Team</Nav.Link>
                        </>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
