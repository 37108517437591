// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-color{
  color: #FFD700;
}

.bg-gold {
  background-color: #FFD700; 
}

.red{
  color: #ff0000;
}


.card-link {
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease;
}

.card-link:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.profile-picture {
  object-fit: cover;
  height: 200px; /* Adjust the size as needed */
  width: 200px; /* Adjust the size as needed */
  border-radius: 50%; /* Create a circular shape */
  border: 3px solid #fff; /* Add a border for better visibility */
  margin: 0 auto; /* Center the image horizontally */
  display: block; /* Remove any inline spacing */
}


.muted-card {
  background-color: #f5f5f5; /* Grey background color */
  opacity: 0.6; /* Adjust opacity to control the level of greyness */
  pointer-events: none; /* Make the card unclickable */
  filter: grayscale(100%); /* Apply grayscale effect */
}
`, "",{"version":3,"sources":["webpack://./src/components/Components.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;;AAGA;EACE,qBAAqB;EACrB,cAAc;EACd,gCAAgC;AAClC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,iBAAiB;EACjB,aAAa,EAAE,8BAA8B;EAC7C,YAAY,EAAE,8BAA8B;EAC5C,kBAAkB,EAAE,4BAA4B;EAChD,sBAAsB,EAAE,uCAAuC;EAC/D,cAAc,EAAE,kCAAkC;EAClD,cAAc,EAAE,8BAA8B;AAChD;;;AAGA;EACE,yBAAyB,EAAE,0BAA0B;EACrD,YAAY,EAAE,oDAAoD;EAClE,oBAAoB,EAAE,8BAA8B;EACpD,uBAAuB,EAAE,2BAA2B;AACtD","sourcesContent":[".custom-color{\n  color: #FFD700;\n}\n\n.bg-gold {\n  background-color: #FFD700; \n}\n\n.red{\n  color: #ff0000;\n}\n\n\n.card-link {\n  text-decoration: none;\n  color: inherit;\n  transition: box-shadow 0.3s ease;\n}\n\n.card-link:hover {\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.profile-picture {\n  object-fit: cover;\n  height: 200px; /* Adjust the size as needed */\n  width: 200px; /* Adjust the size as needed */\n  border-radius: 50%; /* Create a circular shape */\n  border: 3px solid #fff; /* Add a border for better visibility */\n  margin: 0 auto; /* Center the image horizontally */\n  display: block; /* Remove any inline spacing */\n}\n\n\n.muted-card {\n  background-color: #f5f5f5; /* Grey background color */\n  opacity: 0.6; /* Adjust opacity to control the level of greyness */\n  pointer-events: none; /* Make the card unclickable */\n  filter: grayscale(100%); /* Apply grayscale effect */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
