import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="text-white bg-black">
      <Container fluid className="py-5">
        <Row>
          <Col className="col-12 col-md">
            <div className=" mx-5 pt-5 mt-2">
              <h5 className="pb-3">Contact</h5>
              <p className="text-white" style={{ textDecoration: "none", fontSize: '15px'  }} href="/">Email: contact@zenstarai.com</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-md text-center">
            <div className=" mx-5 pt-5 mt-2">
              <p className="pb-3 text-gray">COPYRIGHT © 2023 Zen Star AI Research Academy. All Rights Reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
