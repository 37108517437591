import React from "react";
import { Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./components/Home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Team from "./components/Team"



function App() {


  return (
    <div>
      
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/team" component={Team} />
      </Switch>
      <Footer />

    </div>


  );
}

export default App;
